import {
  Input, Select, DatePicker, TimePicker, Switch, RadioGroup as Radio, ColorPicker, CheckboxGroup as Checkbox,
  Divider, Slider, Rate, InputNumber,
} from 'element-ui';

export {
  Input,
  Select,
  DatePicker,
  TimePicker,
  Switch,
  Radio,
  ColorPicker,
  Checkbox,
  Divider,
  Slider,
  Rate,
  InputNumber,
};
