<script>
import TablePage from '../../../../components/table_page';
import Form from '../../../process/components/process_approve/approve.vue';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      tablePage: {
        pageSize: 8,
      },
      configs: {
        pagination: false,
        tableConfig: {
          border: 'inner',
        },
      },
      isCalculateHeight: false,
    };
  },
  created() {
    this.getConfigList('home-message');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.$router.push({ path: '/mdm_center/announcement_manage' });
      }
    },
  },
};
</script>
