export default {
  props: {
    value: {
      type: Array,
      default: () => [
        {
          key: '选项一',
          value: '1',
        },
        {
          key: '选项二',
          value: '2',
        },
      ],
    },
  },
  data() {
    return {};
  },
  computed: {
    optionsData: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    addOptions() {
      this.optionsData.push({
        key: '新选项',
        value: '新选项',
      });
    },
    delOptions(index) {
      this.optionsData.splice(index, 1);
    },
  },
  render(h) {
    return (
      <div>
        {this.optionsData.map((item, index) => (
          <el-row gutter={2}>
            <el-col span={10}>
              <el-input v-model={item.value} placeholder="请输入value" />
            </el-col>
            <el-col span={10}>
              <el-input v-model={item.key} placeholder="请输入key" />
            </el-col>
            <el-col span={2}>
              <el-button type="danger" icon="el-icon-delete" size="mini" circle onClick={() => this.delOptions(index)}></el-button>
            </el-col>
          </el-row>
        ))}
        <el-button type="text" onClick={() => this.addOptions()}>添加选项</el-button>
      </div>
    );
  },
};
