import * as FormControl from './form-control/index';
import Properties from './properties';
import './index.less';
import Draggable from '../../plugins/vuedraggable';
import ControlRender from './renderItem';
import { isVueOptions } from '../../utils';
import FormCreate from './form-create';
import { ControlName } from './form-control/control-name';

const getDefault = (propsDefault) => {
  switch (Object.prototype.toString.call(propsDefault)) {
    case '[object Object]':
      return Object;
    case '[object String]':
      return String;
    case '[object Number]':
      return Number;
    case '[object Boolean]':
      return Boolean;
    case '[object Function]':
      return Function;
    case '[object Array]':
      return Array;
    case '[object Date]':
      return Date;
    case '[object document]':
      return HTMLDocument;
    case '[object Symbol]':
      return Symbol;
    case '[object Undefined]':
      return undefined;
    case '[object Null]':
      return null;
    case '[object global]':
      return global;
    default:
      return propsDefault;
  }
};

/**
 * 获取所有组件
 */
const GetControl = () => {
  console.log(FormControl);
  console.log(Object.entries({ ...FormControl }));
  const controls = Object.entries({ ...FormControl }).map(([name, control], index) => {
    const originalProps = {};
    let ctr = null;
    console.log(name, control);
    if (isVueOptions(control)) {
      if (getDefault(control.props) === Array) {
        if (control.props) {
          Object.values(control.props).forEach((k) => {
            if (k) {
              const propsDefault = getDefault(control.props[k].default);
              if (propsDefault === Function) {
                originalProps[k] = control.props[k].default();
              } else if (!control.props[k].default) {
                originalProps[k] = undefined;
              } else {
                originalProps[k] = control.props[k].default;
              }
            }
          });
        }
      } else if (control.props) {
        Object.keys(control.props).forEach((k) => {
          if (k) {
            const propsDefault = getDefault(control.props[k].default);
            if (propsDefault === Function) {
              originalProps[k] = control.props[k].default();
            } else if (!control.props[k].default) {
              originalProps[k] = undefined;
            } else {
              originalProps[k] = control.props[k].default;
            }
          }
        });
      }
      ctr = {
        control,
        id: index,
        componentName: name,
        props: {
          originalProps,
          ...originalProps,
          length: '__vue_devtool_undefined__',
          field: '',
          title: name,
          placeholder: '',
          // label的宽度
          labelWidth: 100,
          // 控件的宽度
          controlWidth: 100,
          span: 12,
          prefix: '',
          suffix: '',
          readonly: false,
          disabled: false,
          required: false,
        },
      };
    }
    return ctr;
  });
  return controls.filter((control) => !!control);
};
console.log(new GetControl());
export default {
  name: 'FormDesign',
  components: {
    Properties,
    Draggable,
  },
  data() {
    return {
      // 组件列表
      components: new GetControl(),

      // 表单设计组件
      design: [],
      // 当前表单的属性
      formConfig: {
        // 当前表单控件集合
        controls: [],
        // 表单控件size
        size: 'mini',
        // label的位置
        labelPposition: 'right',
        // 栅格的间隔
        gutter: 10,
        // 栅格的配置
        col: {
          offset: 0,
          gutter: 0,
          span: 12,
          push: 0,
          pull: 0,
        },
        // form的控制方式 的类型 有 modal drawer ask full simple
        // 所有form通过一个button 或者 一个event 来触发    simple的形式就是直接提交，没有预览或者修改。其他都会出现一个弹出层，可以对表单进行修改
        modalType: 'modal',
        // 弹出层的宽度
        windowWidth: 60,
        // 表单禁用
        disabled: false,
      },
      // 当前选中组件的属性
      controlConfig: {},
      // 当前选中组件的索引
      controlIndex: 0,
      // 当前选中组件
      control: null,
      dialogProps: {
        showClose: false,
        top: '0',
        fullscreen: true,
        footer: null,
        centered: true,
        style: { height: '100%' },
        bodyStyle: { height: '100%' },
        width: '100%',
        visible: true,
        wrapClassName: 'flex-colum form-design',
      },
      // 组件的options数据
      optionsData: [
        {
          key: '选项一',
          value: '1',
        },
        {
          key: '选项二',
          value: '2',
        },
      ],
      // 有options的组件
      hasOptions: ['Select', 'Radio', 'Checkbox'],
      isDragging: false,
      modalConfig: {
        visible: false,
        title: '表单预览',
      },
    };
  },
  watch: {
    $route(v) {
      console.log(v);
      if (v.path === '/form-design') {
        this.dialogProps.visible = true;
      }
    },
  },
  computed: {
    componentsDraggableProps: {
      get() {
        return {
          attrs: {
            group: { name: 'form', pull: 'clone', put: false },
            // clone: this.clone,
            sort: false,
            ghostClass: 'ghost',
          },
          props: {
            move: this.handleMove,
          },
          class: {
            draggable: true,
          },
          on: {
            start: () => (this.isDragging = true),
            end: () => (this.isDragging = false),
          },
        };
      },
      set() {},
    },
    designDraggableProps: {
      get() {
        return {
          attrs: {
            group: 'form',
            animation: 200,
            ghostClass: 'ghost',
            handle: '.drag-widget',
          },
          class: {
            draggable: true,
            'form-list': true,
            full: true,
          },
          on: {
            add: this.handleWidgetAdd,
          },
        };
      },
      set() {},
    },
  },
  mounted() {},
  methods: {
    handleMove(e) {
      // window.console.log(`Future index: ${e.draggedContext.futureIndex}`);
      return true;
    },
    handleWidgetAdd(evt) {
      const { newIndex } = evt;
      const { to } = evt;
      // 为拖拽到容器的元素添加唯一 key
      const key = `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}`;
      this.$set(this.formConfig.controls, newIndex, {
        ...this.formConfig.controls[newIndex],
        key,
        props: {
          ...this.formConfig.controls[newIndex].props,
          title: ControlName[this.formConfig.controls[newIndex].props.title],
          optionsData: this.hasOptions.includes(this.formConfig.controls[newIndex].componentName) ? this.optionsData : null,
        },
      });
      if (this.formConfig.controls[newIndex].type === 'grid') {
        this.$set(this.formConfig.controls, newIndex, {
          ...this.formConfig.controls[newIndex],
          columns: this.formConfig.controls[newIndex].columns.map((item) => ({ ...item })),
        });
      }
      this.selectControl(newIndex);
    },
    /**
     * 复制
     * @param original
     */
    clone(original) {
      const id = this.formConfig.controls.length;
      const json = { ...original, id };
      return json;
    },
    /**
     * 保存
     */
    formSave() {
      console.log(this.formConfig);
    },
    /**
     * 预览表单
     */
    viewForm() {
      this.modalConfig.visible = true;
      console.log(this.modalConfig);
    },
    /**
     * 设置控件index
     * @param index
     */
    selectControl(index) {
      this.controlIndex = index;
      this.control = this.formConfig.controls[index];
    },
    /**
     * 删除组件
     * @param {*} index
     */
    deleteControl(index) {
      console.log(index);
      this.formConfig.controls.splice(index, 1);
    },
    copyControl(index) {
      const tempControl = this.formConfig.controls[index];
      this.formConfig.controls.splice(index, 0, {
        ...tempControl,
        id: this.formConfig.controls.length,
        key: `${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}`,
        props: JSON.parse(JSON.stringify(tempControl.props)),
      });
    },
    getControlRenderSlots(control) {
      if (control) {
        if (control.control) return false;
      }
    },
  },
  render(h) {
    const deleteControl = this.deleteControl.bind(this);
    const copyControl = this.copyControl.bind(this);
    const comp = new GetControl();
    const isSelected = (key) => {
      if (this.control) {
        return this.control.key === key;
      }
      return false;
    };
    const { controls, ...formConfig } = this.formConfig;
    return h(
      'div', {

      },
      [h('a-modal',
        {
          props: this.dialogProps,
          on: {
            cancel: () => {
              this.$router.back();
              this.dialogProps.visible = false;
            },
          },
        },
        [
          h('div', { slot: 'title' }, [<el-divider>表单设计</el-divider>]),
          h(
            'el-row',
            {
              props: {
                type: 'flex',
                gutter: 10,
              },
            },
            [
              h(
                'el-col',
                {
                  props: {
                    span: 4,
                  // value: this.components,
                  },
                },
                [
                <ElRow gutter={formConfig.gutter}>
                  <div>
                    <Draggable list={comp} {...this.componentsDraggableProps}>
                      {comp.map((control) => (
                        <div class="el-col el-col-12 left-form-control control" key={control.id}>
                          <div>{ControlName[control.props.title]}</div>
                        </div>
                      ))}
                    </Draggable>
                  </div>
                </ElRow>,
                ],
              ),
              h(
                'el-col',
                {
                  props: {
                    span: 14,
                    className: {
                      box: true,
                    },
                  },
                },
                [
                <div class="widget-form-container">
                  <div class="form-option">
                    <ElLink onClick={() => this.viewForm()}>
                      <i class="el-icon-view el-icon--right"></i> 预览
                    </ElLink>
                    <ElLink onClick={() => this.formSave()}>
                      <i class="el-icon-edit"></i> 保存
                    </ElLink>
                    <ElLink type="danger" onClick={() => {
                      this.$router.back();
                      this.dialogProps.visible = false;
                    }}>关闭</ElLink>
                  </div>
                  <ElForm
                    v-model={formConfig}
                    size={formConfig.size}
                    label-position={formConfig.labelPposition}
                    label-width={`${formConfig.labelWidth}px`}
                    disabled={formConfig.disabled}
                  >
                    <Draggable list={controls} {...this.designDraggableProps}>
                      {controls.length
                        ? controls.map((item, index) => {
                          console.log(item);
                          const {
                            control, id, key, componentName,
                          } = item;
                          return (
                              <ControlRender
                                key={key}
                                id={id}
                                props={{ type: 'textarea' }}
                                index={index}
                                controlConfig={item}
                                formConfig={formConfig}
                                control={control}
                                deleteControl={deleteControl}
                                name={componentName}
                                selected={isSelected(key)}
                                copyControl={copyControl}
                                handleSelectWidget={this.selectControl}
                                nativeOnClick={() => this.selectControl(index)}
                              />
                          );
                        })
                        : null}
                    </Draggable>
                  </ElForm>
                </div>,
                ],
              ),

              h(
                'el-col',
                {
                  props: {
                    span: 6,
                    value: this.components,
                    className: {
                      box: true,
                    },
                  },
                },
                [<Properties v-model={this.formConfig} index={this.controlIndex}></Properties>],
              ),
            ],
          ),
        <FormCreate
          formConfig={{ form: { ...this.formConfig }, modal: this.modalConfig }}
        ></FormCreate>,
        ])],
    );
  },
};
