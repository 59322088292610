import OptionsData from './components/optionsData';

export default {
  name: 'controlConfig',
  data() {
    return {
      active: 'component',
      showExt: ['Divider', 'Select', 'Radio', 'Checkbox'],
    };
  },
  components: { OptionsData },
  props: {
    value: {
      type: Object,
      default() {
        return {
          props: {
            field: '', // 字段名
            title: '', // label
            placeholder: '', // 占位符号
            labelWidth: 100, // label宽度
            controlWidth: 100, // 控件宽度 百分比
            readonly: false, // 是否只读
            disabled: false, // 是否禁用
            required: false, // 是否必填
            span: 12, // 栅格宽度
          },
        };
      },
    },
  },
  computed: {
    controlConfig: {
      get() {
        return this.value.props;
      },
      set(v) {
        this.$emit('input', { ...this.value, props: v });
      },
    },
  },
  methods: {
    createExt() {
      switch (this.value.componentName) {
        case 'Divider':
          return (
            <el-col span={24}>
              <el-form-item prop={'extTitle'} label={'标题名称'}>
                <el-input v-model={this.controlConfig.extTitle}></el-input>
              </el-form-item>
            </el-col>
          );
        case 'Select':
          return (
            <el-col span={24}>
              {/* <el-form-item prop={'extTitle'} label={'选项'}> */}
                <OptionsData v-model={this.controlConfig.optionsData}></OptionsData>
              {/* </el-form-item> */}
            </el-col>
          );
        case 'Radio':
          return (
              <el-col span={24}>
                {/* <el-form-item prop={'extTitle'} label={'选项'}> */}
                  <OptionsData v-model={this.controlConfig.optionsData}></OptionsData>
                {/* </el-form-item> */}
              </el-col>
          );
        case 'Checkbox':
          return (
                <el-col span={24}>
                  {/* <el-form-item prop={'extTitle'} label={'选项'}> */}
                    <OptionsData v-model={this.controlConfig.optionsData}></OptionsData>
                  {/* </el-form-item> */}
                </el-col>
          );
        default:
          break;
      }
    },
  },
  render(h) {
    return h(
      'el-form',
      {
        props: {
          size: 'mini',
          'label-position': 'left',
          'label-width': '100px',
          value: this.controlConfig,
          model: this.controlConfig,
        },
      },
      [
        <el-row>
          <el-col>
            <el-form-item prop={'field'} label={'字段名'}>
              <el-input v-model={this.controlConfig.field}></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop={'title'} label={'标题名'}>
              <el-input v-model={this.controlConfig.title}></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop={'placeholder'} label={'占位文字'}>
              <el-input v-model={this.controlConfig.placeholder}></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop={'span'} label={'栅格宽度'}>
              <el-slider
                v-model={this.controlConfig.span}
                min={0}
                max={24}
                step={1}
                show-stops
              ></el-slider>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop={'labelWidth'} label={'标题宽度(px)'}>
              <el-slider
                v-model={this.controlConfig.labelWidth}
                min={0}
                max={160}
                step={10}
                show-stops
              ></el-slider>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop={'controlWidth'} label={'控件宽度(%)'}>
              <el-slider
                v-model={this.controlConfig.controlWidth}
                min={0}
                max={100}
                step={10}
                show-stops
              ></el-slider>
            </el-form-item>
          </el-col>
          <el-col span={10}>
            <el-form-item prop={'required'} label={'是否必填'}>
              <el-switch v-model={this.controlConfig.required}></el-switch>
            </el-form-item>
          </el-col>
          <el-col span={10}>
            <el-form-item prop={'disabled'} label={'是否禁用'}>
              <el-switch v-model={this.controlConfig.disabled}></el-switch>
            </el-form-item>
          </el-col>
          <el-col span={10}>
            <el-form-item prop={'readonly'} label={'是否只读'}>
              <el-switch v-model={this.controlConfig.readonly}></el-switch>
            </el-form-item>
          </el-col>
          {this.showExt.includes(this.value.componentName) && (
            <el-col span={24}>
              <el-divider content-position="left">组件特有属性</el-divider>
            </el-col>
          )}
          {this.createExt()}
        </el-row>,
      ],
    );
  },
};
