import Vue from 'vue';
import Element from 'element-ui';
import FormItem from './form-create/form/formItem';
import { isVueOptions } from '../../utils';

const slotComponents = {
  tableColumn: Element.TableColumn,
};

const FormItemWrapComponent = {
  name: 'FormItemWrapComponent',
  functional: true,
  render(h, context) {
    console.log(context.props.formItemProps);
    return h(
      'el-col',
      {
        props: { ...context.props.colConfigProps, span: context.props.controlConfig.span },
        style: { minHeight: '60px' },
      },
      [<el-form-item {...context.props.formItemProps}>{context.children}</el-form-item>],
    );
  },
};

const FormItemOperation = {
  name: 'FormItemOperation',
  functional: true,
  render(h, context) {
    const {
      props: { copyControl, deleteControl, index },
    } = context;
    return (
      <div>
        <div class="widget-view-action">
          <i class="el-icon-copy-document" on-click={() => copyControl(index)}></i>
          <i class="el-icon-delete" on-click={() => deleteControl(index)}></i>
        </div>
        <div class="widget-view-drag">
          {h('i', {
            class: {
              'drag-widget': true,
              'el-icon-rank': true,
              ...context.props.className,
            },
          })}
        </div>
      </div>
    );
  },
};

const FormItemChildren = {
  name: 'FormItemChildren',
  functional: true,
  components: {
    // wrapComponent, operation,
  },
  render(h, context) {
    const {
      selected,
      copyControl,
      deleteControl,
      index,
      control,
      colConfigProps,
      formItemProps,
      controlConfig: { controlWidth, ...controlConfig },
      className,
    } = context.props;
    const formItemControlOption = [
      <form-item control={controlConfig} formModel={{}}></form-item>,
      selected
        ? h('form-item-operation', {
          style: {
            display: selected ? 'block' : 'none',
          },
          props: {
            copyControl,
            deleteControl,
            index,
            // className,÷
          },
        })
        : null,
    ];

    return isVueOptions(control)
      ? h(
        'form-item-wrap-component',
        {
          props: {
            colConfigProps,
            formItemProps,
            controlConfig: controlConfig.props,
          },
        },
        formItemControlOption,
      )
      : null;
  },
};

const renderFormItem = {
  name: 'renderFormItem',
  data() {
    return {
      childList: [],
    };
  },
  methods: {
    handleWidgetColumnAdd($event, row, colIndex) {
      console.log('coladd', $event, row, colIndex);
    },
  },
  // components: { children },
  render(h) {
    const {
      selected,
      copyControl,
      deleteControl,
      index,
      control,
      name,
      controlConfig,
      formConfig: { col },
      handleSelectWidget,
      className,
    } = this.$attrs;
    console.log(this.$attrs);
    const normalRender = h('form-item-children', {
      props: {
        className,
        selected,
        copyControl,
        deleteControl,
        index,
        name,
        control,
        colConfigProps: {
          ...col,
        },
        formItemProps: {
          props: {
            'label-width': `${String(controlConfig.props.labelWidth)}px`,
            label: controlConfig.props.label || controlConfig.props.title,
            labelPosition: controlConfig.props.labelPosition,
            prop: controlConfig.props.field,
            rules: controlConfig.props.rules,
            required: controlConfig.props.required,
            optionsData: controlConfig.props.optionsData,
          },
          class: {
            'widget-view': true,
            active: selected,
            // is_req: controlConfig.required,
          },
        },
        controlConfig,
      },
    });
    const column = [
      {
        key: '',
        prop: '',
        name: '',
      },
    ];
    const { childList } = this;
    return normalRender;
  },
};

Vue.component('form-item-wrap-component', FormItemWrapComponent);
Vue.component('form-item-operation', FormItemOperation);
Vue.component('form-item-children', FormItemChildren);
// Vue.component('form-item-with-slot-render', FormItemWithSlotRender);
Vue.component('render-form-item', renderFormItem);
Vue.component('form-item', FormItem);

export default renderFormItem;
