<!-- 外层盒子 -->
<template>
  <div class="box">
    <div class="box_top">
      <div class="box_title">{{title}}</div>
      <div class="box_icon">
        <i class="el-icon-refresh" @click="refreshFn"></i>
        <!-- <i class="el-icon-setting"></i>
        <i class="el-icon-more"></i> -->
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    type: String,
  },
  methods: {
    refreshFn() {
      this.$emit('refresh', this.type);
    },
  },
};
</script>
<style lang="less" scoped>
  .box{
    width: 100%;
    height: 100%;
    background-color: #fff;
    // border: 1px solid #e1e1e1;
    padding: 0 10px 10px 10px;
    // margin-top: 10px;
  }
  .box_top{
    padding: 10px 0;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    justify-content: space-between;
    align-content: center;
    .box_title{
      font-size: 18px;
      color: #333;
    }
    i{
      font-size: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
    .box_icon{
      display: flex;
      align-items: center;
    }
  }
</style>
