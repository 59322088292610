export default {
  name: 'formItem',
  props: {
    control: Object,
    formModel: Object,
  },
  data() {
    return {
      checkboxGroupModel: [], // 多选框组model
    };
  },
  computed: {
    formData: {
      get() {
        return this.formModel;
      },
      set(v) {
        console.log(v);
        this.$emit('input', { ...this.formModel, [this.control.props.field]: v });
      },
    },
  },
  created() {
  },
  methods: {
    /**
     * checkboxChange事件
     */
    checkboxChange(type, value) {
      this.formData[type] = value;
      console.log(this.formData);
    },
    // 根据不同的组件类型生成对应的options
    createOptions(name, optionsData) {
      switch (name) {
        case 'Select':
          if (Array.isArray(optionsData)) {
            const tempArray = optionsData.map((item) => ({
              type: 'el-option',
              key: item.value,
              value: item.value,
              label: item.key,
              showText: item.key,
            }));
            return tempArray;
          }
          return [];
        case 'Radio':
          if (Array.isArray(optionsData)) {
            const tempArray = optionsData.map((item) => ({
              type: 'el-radio',
              key: item.key,
              label: item.value,
              showText: item.key,
            }));
            return tempArray;
          }
          return [];
        case 'Checkbox':
          if (Array.isArray(optionsData)) {
            const tempArray = optionsData.map((item) => ({
              type: 'el-checkbox',
              label: item.value,
              showText: item.key,
            }));
            return tempArray;
          }
          return [];
        default:
          return [];
      }
    },
    /**
     * 生成组件的公共属性
     */
    formItemAttribute(control) {
      const controlProps = {
        disabled: control.props.disabled,
        placeholder: control.props.placeholder || control.props.title,
        readonly: control.props.readonly,
      };
      return {
        attrs: {
          ...controlProps,
        },
        props: {},
        on: {
          change: (e) => console.log(this.formData),
        },
        style: { width: `${control.props.controlWidth}%` },
      };
    },
    /**
     * 根据不同类型渲染不同组件
     */
    createControl(control) {
      console.log(control);
      const childrenList = this.createOptions(control.componentName, control.props.optionsData).map(
        (item) => h(
          item.type,
          {
            attrs: {
              ...item,
            },
          },
          [item.showText],
        ),
      );
      switch (control.componentName) {
        case 'Input':
          return (
            <el-input
              {...this.formItemAttribute(control)}
              v-model={this.formData[control.props.field]}
            ></el-input>
          );
        case 'Select':
          return (
            <el-select
              {...this.formItemAttribute(control)}
              v-model={this.formData[control.props.field]}
            >
              {childrenList}
            </el-select>
          );
        case 'DatePicker':
          return (
            <el-date-picker
              {...this.formItemAttribute(control)}
              value-format='yyyy:MM:dd HH:mm:ss'
              v-model={this.formData[control.props.field]}
            ></el-date-picker>
          );
        case 'TimePicker':
          return (
            <el-time-picker
              {...this.formItemAttribute(control)}
              value-format='HH:mm:ss'
              v-model={this.formData[control.props.field]}
            ></el-time-picker>
          );
        case 'Switch':
          return (
            <el-switch
              {...this.formItemAttribute(control)}
              v-model={this.formData[control.props.field]}
            ></el-switch>
          );
        case 'Radio':
          return (
            <el-radio-group
              {...this.formItemAttribute(control)}
              v-model={this.formData[control.props.field]}
            >
              {childrenList}
            </el-radio-group>
          );
        case 'ColorPicker':
          return (
            <el-color-picker
              {...this.formItemAttribute(control)}
              v-model={this.formData[control.props.field]}
            ></el-color-picker>
          );
        case 'Checkbox':
          return (
            <el-checkbox-group
              {...this.formItemAttribute(control)}
              v-model={this.checkboxGroupModel}
              onChange={(e) => this.checkboxChange(control.props.field, e)}
            >
              {childrenList}
            </el-checkbox-group>
          );
        case 'Divider':
          return (
            <el-divider content-position="left">{control.props.extTitle}</el-divider>
          );
        case 'Slider':
          return (
              <el-slider {...this.formItemAttribute(control)} v-model={this.formData[control.props.field]}></el-slider>
          );
        case 'Rate':
          return (
              <el-rate {...this.formItemAttribute(control)} v-model={this.formData[control.props.field]}></el-rate>
          );
        case 'InputNumber':
          return (
                <el-input-number {...this.formItemAttribute(control)} v-model={this.formData[control.props.field]}></el-input-number>
          );
        default:
          return <fragment></fragment>;
      }
    },
  },
  render(h) {
    return (
      <fragment>
        {this.createControl(this.control)}
      </fragment>
    );
  },
};
