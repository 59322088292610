import FormBase from './form/formBase';

export default {
  components: {
    FormBase,
  },
  props: {
    formConfig: {
      type: Object,
      default: () => ({
        modal: {
          visible: false,
          title: '表单预览',
        },
      }),
    },
  },
  computed: {
    modalConfig: {
      set(v) {
        this.$emit('input', { ...this.formConfig, modal: v });
      },
      get() {
        return this.formConfig.modal;
      },
    },
  },
  methods: {
    /**
     * 关闭弹窗
     */
    onCancel() {
      this.modalConfig.visible = false;
    },
    /**
     * 获取formData
     */
    getFormData(value) {
      if (value) {
        this.$alert(JSON.stringify(value), '表单数据', {
          confirmButtonText: '确定',
        });
      }
    },
    /**
     * 确定弹窗
     */
    async onOk() {
      this.$refs.formBase.getFormData(this.getFormData);
    },
  },

  render(h) {
    const { modalType } = this.formConfig.form;
    return h(
      modalType === 'modal' ? 'a-modal' : 'a-drawer',
      {
        attrs: {
          ...this.modalConfig,
          width: `${this.formConfig.form.windowWidth}%`,
          destroyOnClose: true,
          maskClosable: false,
          cancelText: '取消',
          okText: '确定',
          bodyStyle: { paddingBottom: '80px' },
        },
        on: {
          cancel: () => this.onCancel(),
          ok: () => this.onOk(),
          close: () => this.onCancel(),
        },
      },
      [
          <FormBase ref="formBase" formConfig={this.formConfig.form}></FormBase>,
          <div>
          {modalType === 'drawer' ? (
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
              }}
            >
              <el-button style={{ marginRight: '8px' }} onClick={() => this.onCancel()}>
                取消
              </el-button>
              <el-button type="primary" onClick={() => this.onOk()}>
                确定
              </el-button>
            </div>
          ) : null}
        </div>,
      ],
    );
  },
};
