<template>
  <div class="index">
    <div class="indexNew">
      <el-row :gutter="24" class="top_box">
        <el-col :md="10">
          <div class="index_top">
            <MyBox :title="'消息公告'" @refresh="refreshFn('messageTable')">
                <MessageTable ref="messageTable"></MessageTable>
            </MyBox>
          </div>
        </el-col>
        <el-col :md="14">
          <div class="index_top">
            <MyBox :title="'待处理'" @refresh="refreshFn('todoList')">
              <TodoList ref="todoList"></TodoList>
            </MyBox>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="10">
          <div class="index_bottom">
            <MyBox :title="'我的申请'" class="myApp borderStyle"  @refresh="refreshFn('myApply')">
              <MyApply ref="myApply"></MyApply>
            </MyBox>
          </div>
        </el-col>
        <el-col :md="14">
          <div class="index_bottom">
            <MyBox :title="'被驳回'" class="borderStyle"  @refresh="refreshFn('rejectList')">
              <RejectList ref="rejectList"></RejectList>
            </MyBox>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

import MyBox from './components/my_box.vue'; // 外层盒子
import MessageTable from './components/my_dos/message_list.vue'; // 消息公告
import TodoList from './components/my_dos/todo_list.vue'; // 待处理
import MyApply from './components/my_dos/my_apply.vue'; // 我的申请
import RejectList from './components/my_dos/reject_list.vue';
// 被驳回
export default {
  components: {
    MyBox,
    MessageTable,
    TodoList,
    MyApply,
    RejectList,
  },
  data() {
    return {
      imgList: [
        require('../../assets/img/BI.png'),
        require('../../assets/img/OA.png'),
        require('../../assets/img/CRM.png'),
        require('../../assets/img/SAP.png'),
      ],
      value: '',
      options: [],
    };
  },
  methods: {
    // 选中某天
    clickDay() {},
    // 左右点击切换月份
    changeDate() {},
    // 刷新列表
    refreshFn(demoName) {
      const $demo = this.$refs[`${demoName}`];

      if (demoName === 'mydos') {
        $demo.$refs[`tabs${$demo.currentTab}`].getList();
      } else {
        $demo.getList();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px !important;
  right: 0px !important;
  background-color: #f3f3f3;
}
.indexNew {
  height: 100%;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}
/deep/.content-container {
  background-color: #f3f3f3 !important;
}
/deep/.el-main .content {
  background-color: #f3f3f3 !important;
}
/deep/.box[data-v-34871b32] {
  background-color: #f3f3f3;
}
.top_box {
  display: flex;
  flex: 1;
  margin: 0 0 10px 0;
}
.borderStyle {
  border: 1px solid #e1e1e1;
}
.index_top {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  height: 100%;
  min-height: 260px;
}
.index_bottom {
  min-height: 470px;
  height: calc(60vh - 20px);
}
.myApp {
  margin-bottom: 10px;
}
.index_right_bottom .box:first-child {
  height: 24%;
}
.index_right_bottom .box:last-child {
  height: calc(76% - 10px);
}
.img_item {
  width: 42px;
  height: 42px;
  margin: 12px;
}
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.el-col {
  padding-right: 0 !important;
}
.content-container {
  background-color: #f3f3f3 !important;
}
.el-main .content {
  background-color: #f3f3f3 !important;
}
</style>
<style lang="less">
.el-main .content {
  position: relative;
}
.wh_content_all {
  background-color: #fff !important;
}
.wh_top_changge li,
.wh_content_item {
  color: #333 !important;
}
.wh_content_item .wh_isToday {
  color: #409eff !important;
  background-color: rgba(64, 158, 255, 0.2) !important;
}
.wh_item_date:hover {
  background-color: rgba(64, 158, 255, 0.2) !important;
  border-radius: 100px;
}
.wh_content {
  justify-content: center !important;
}
.wh_top_changge {
  display: none !important;
}
.wh_content_item .wh_chose_day {
  background-color: rgba(64, 158, 255, 0.2) !important;
}
</style>
