<!--
 * @Autor: yzr
 * @Date: 2021-07-16 16:50:03
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-01 10:30:20
 * @Description:
-->
<!--我的申请-->
<script>
import TablePage from '../../../../components/table_page';
import Form from '../../../process/components/process_approve/approve.vue';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      tablePage: {
        // 分页
        pageSize: 8,
      },
      configs: {
        pagination: false,
        tableConfig: {
          border: 'inner',
        },
      },
      params: {
        bpmStatus: '2',
      },
      isCalculateHeight: false,
    };
  },
  created() {
    this.getConfigList('home-my-apply');
  },
  methods: {
    setColumn(col) {
      const rowData = col;
      if (rowData.field === 'processTitle') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => `<span style='color: #409eff;cursor: pointer;'>${cellValue || ''}</span>`;
      }
      return rowData;
    },
    cellClick({ row, column }) {
      if (column.property === 'processTitle') {
        this.modalConfig.title = '查看';
        this.modalConfig.type = 'EmptyDrawer';

        this.formConfig = {
          code: 'view',
          row,
        };
        this.openModal();
      }
    },
  },
};
</script>
