var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("div", { staticClass: "box_top" }, [
        _c("div", { staticClass: "box_title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "box_icon" }, [
          _c("i", {
            staticClass: "el-icon-refresh",
            on: { click: _vm.refreshFn },
          }),
        ]),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }