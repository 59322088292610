const ControlName = {
  Input: '文本框',
  Select: '下拉框',
  DatePicker: '日期选择器',
  TimePicker: '时间选择器',
  Switch: '开关',
  Radio: '单选框',
  ColorPicker: '颜色选择器',
  Checkbox: '多选框',
  Divider: '标题',
  Slider: '滑块',
  Rate: '评分',
  InputNumber: '计数器',
};

const extAttrs = {
  Divider: [
    {
      props: {
        label: '标题名称',
        field: 'extTitle',
      },
      componentName: 'Input',
    },
  ],
};
export {
  ControlName,
  extAttrs,
};
