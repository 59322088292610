import FormItem from './formItem';

export default {
  name: 'formBase',
  props: {
    formConfig: Object,
  },
  components: { FormItem },
  data() {
    return {
      formAttrs: {
        ref: 'form',
        labelWidth: '100px',
      },
      formData: {},
    };
  },
  computed: {
    formControls() {
      return this.formConfig.controls;
    },
  },
  created() {},
  methods: {
    /**
     * 获取表单值
     */
    getFormData(v) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          v(this.formData);
        }
        return false;
      });
    },
  },
  render(h) {
    return h(
      'el-form',
      {
        attrs: {
          ...this.formAttrs,
          labelPosition: this.formConfig.labelPposition,
          size: this.formConfig.size,
          disabled: this.formConfig.disabled,
          model: this.formData,
        },
        ref: 'form',
        props: {
          model: this.formData,
          value: this.formData,
        },
      },
      [
        <el-row gutter={this.formConfig.col.gutter}>
          {this.formControls.map((item) => (
            <el-col span={item.props.span} style={{ minHeight: '60px' }}>
              {/* <form-item control={item} formModel={this.formData}></form-item> */}
              <el-form-item
                required={item.props.required}
                label={item.props.title}
                prop={item.props.field}
                labelWidth={`${item.props.labelWidth.toString()}px`}
              >
                <form-item control={item} formModel={this.formData}></form-item>
              </el-form-item>
            </el-col>
          ))}
        </el-row>,
      ],
    );
  },
};
