import ControlConfig from './controlConfig';
import FormConfig from './formConfig';

export default {
  name: 'properties',
  data() {
    return {
      active: 'component',
    };
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return -1;
      },
    },
    // formConfig: {
    //   type: Object,
    //   default() {
    //     return {
    //       gutter: 10,
    //     };
    //   },
    // },
  },
  computed: {
    controlConfig: {
      get() {
        console.log(this.formConfig.controls);
        return this.formConfig.controls[this.index];
      },
      set(v) {
        this.formConfig.controls.splice(this.index, 1, { ...v });
      },
    },
    formConfig: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    // otherProperties: {
    //   get() {
    //     return this.value.otherProperties;
    //   },
    //   set(v) {
    //     this.$emit('input', { ...this.value, otherProperties: v });
    //   },
    // },
  },
  methods: {
    handleClick(active) {
      this.active = active.name;
    },
  },
  render() {
    return <el-col span={Number(this.$attrs.span)}>
      <div className='right-form'>
        <el-tabs v-model={this.active} on-tab-click={this.handleClick} className={'sticky-top'}>
          <el-tab-pane label="组件属性" name="component">
            <ControlConfig v-model={this.controlConfig}></ControlConfig>
          </el-tab-pane>
          <el-tab-pane label="表单属性" name="form">
            <FormConfig v-model={this.formConfig}></FormConfig>
          </el-tab-pane>
          {/* <el-tab-pane label="其他属性" name="other"> */}
          {/*  <el-form v-model={this.otherProperties}></el-form> */}
          {/* </el-tab-pane> */}
        </el-tabs>
      </div>
    </el-col>;
  },
};
