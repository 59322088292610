var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _c(
      "div",
      { staticClass: "indexNew" },
      [
        _c(
          "el-row",
          { staticClass: "top_box", attrs: { gutter: 24 } },
          [
            _c("el-col", { attrs: { md: 10 } }, [
              _c(
                "div",
                { staticClass: "index_top" },
                [
                  _c(
                    "MyBox",
                    {
                      attrs: { title: "消息公告" },
                      on: {
                        refresh: function ($event) {
                          return _vm.refreshFn("messageTable")
                        },
                      },
                    },
                    [_c("MessageTable", { ref: "messageTable" })],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { md: 14 } }, [
              _c(
                "div",
                { staticClass: "index_top" },
                [
                  _c(
                    "MyBox",
                    {
                      attrs: { title: "待处理" },
                      on: {
                        refresh: function ($event) {
                          return _vm.refreshFn("todoList")
                        },
                      },
                    },
                    [_c("TodoList", { ref: "todoList" })],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c("el-col", { attrs: { md: 10 } }, [
              _c(
                "div",
                { staticClass: "index_bottom" },
                [
                  _c(
                    "MyBox",
                    {
                      staticClass: "myApp borderStyle",
                      attrs: { title: "我的申请" },
                      on: {
                        refresh: function ($event) {
                          return _vm.refreshFn("myApply")
                        },
                      },
                    },
                    [_c("MyApply", { ref: "myApply" })],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { md: 14 } }, [
              _c(
                "div",
                { staticClass: "index_bottom" },
                [
                  _c(
                    "MyBox",
                    {
                      staticClass: "borderStyle",
                      attrs: { title: "被驳回" },
                      on: {
                        refresh: function ($event) {
                          return _vm.refreshFn("rejectList")
                        },
                      },
                    },
                    [_c("RejectList", { ref: "rejectList" })],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }